<template>
    <section class="settings_block">
        <header class="settings_block__header">
            <hgroup>
                <h2 class="headline6 settings_block__title">{{ title }}</h2>
                <h3 v-if="helpText" class="body2">{{ helpText }}</h3>
            </hgroup>
        </header>
        <section class="settings_block__section">
            <slot></slot>
        </section>
    </section>
</template>

<script>
export default {
    name: "SettingsBlock",
    props: {
        title: {
            type: String,
            required: true,
        },
        helpText: {
            type: String,
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/style_variables/style_variables.scss";

.settings_block {
    background: $white;
    border: 1px solid $grey_alabaster;
    border-radius: 8px;
    padding: 24px;
    width: 100%;
}
.settings_block__header {
    border-bottom: 1px solid $grey_alabaster;
    padding-bottom: 24px;
}
.settings_block__title {
    margin: 0;
}
</style>
