<template>
    <section class="formgroup" :style="{ maxWidth }">
        <header class="formgroup__header" v-if="label || helpText">
            <span class="formgroup__header__title subtitle1">
                <strong>{{ label }}</strong>
            </span>
            <span v-if="helpText" class="formgroup__header__subtext body2">
                {{ helpText }}
            </span>
        </header>
        <slot></slot>
    </section>
</template>

<script>
export default {
    name: "SettingsSection",
    props: {
        label: {
            type: String,
            required: true,
        },
        helpText: {
            type: String,
        },
        maxWidth: {
            type: String,
            default: "400px",
        },
    },
};
</script>

<style scoped lang="scss">
@import "@/style_variables/style_variables.scss";

.formgroup {
    margin-top: 28px;
}
.formgroup__header {
    margin-bottom: 12px;
    padding: 0;
}
.formgroup__header__title {
    display: block;
}
.formgroup__header__subtext {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
    max-width: 500px;
}
</style>
