import i18n from "@/i18n";

/**
 * This dictionary uses the name of the predicate/validator as a key.
 * The value's are the errorMessages that need to be shown when the predicate returned false.
 * Or functions which invocation shall provide those errorMessages.
 */
export const errorMessages = () => {
    return {
        required: i18n.t("cp__validation__error__required"),
        minValue: (params) => i18n.t("cp__validation__error__min_value", { min: params.min }),
        email: i18n.t("cp__validation__error__email"),
        isNotDuplicateEmail: i18n.t("cp__validation__error__duplicate_notifications_email"),
        numeric: i18n.t("cp__validation__error__numeric"),
        isOneOf: (params) => {
            if (params.optionsLabels === null) {
                return "cp__validation__error__is_one_of";
            }

            return i18n.t("cp__validation__error__is_one_of--these-options", {
                values: params.optionsLabels.join(", "),
            });
        },
        qualityMarkURLRequired: i18n.t("cp_validation__error__quality_mark_url_required"),
        validURL: i18n.t("cp_validation__error__url_not_valid"),
        validQualityMarkUrl: (params) =>
            i18n.t("cp_validation__error__url_has_not_required_shape", {
                name: params.options.mark.name,
                urlShape: params.options.urlShape,
            }),
        maxLength: (params) =>
            i18n.t("cp__validation__error__too_many_characters", { max: params.max }),
        hasProperHeightAndWidth: ({ options }) =>
            i18n.t("cp_validation__error__has_proper_height_and_width", {
                maxWidth: options.maxWidth,
                maxHeight: options.maxHeight,
            }),
    };
};
